import React, { useEffect, useState } from "react";
import { Button } from '@ctt/ui';
import { emitEvent } from '@ctt/utils';
import axios from "axios";

const BASE_URL = 'http://localhost:3000/tarefas'

export default function Root(props) {

  const [item, setItem] = useState(1); 

  useEffect(() => {
    // fetch(`${BASE_URL}/${item}`)
    // .then(response => response.json())
    // .then(json => console.log(json))
    axios.get(`${BASE_URL}/${item}`).then((response) => {
      console.log(':::::', response.data);
    }).catch(err => {
      console.error('Error message:', err.message);
    })
  }, [item])

  function addToCart() {
    setItem((prevTotal) => {
      const sumTotal = prevTotal + 1;
      return sumTotal;
    });
    emitEvent('@ctt/process/add-cart', {item: 1})
  }

  return (
    <section>
      <Button onClick={addToCart}>Processo 01</Button>
      {props.name} is mounted!!
    </section>
  )
}
